import Button from "@mui/material/Button";
import { useState } from "react";
import UploadTemplateModal from "pages/CompetencyMaps/list/components/UploadTemplate/UploadTemplateModal";

const UploadTemplate = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  return (
    <>
      <Button
        variant='outlined'
        onClick={handleOpen}>
        Загрузить шаблон
      </Button>
      {openModal && (
        <UploadTemplateModal
          open={openModal}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default UploadTemplate;
