import { ToastContext, ToastTypeEnum } from "context/toastContext";
import {
  CreateOrUpdateCompanyCandidateLoginMutationResult,
  CreateOrUpdateCompanyCandidateMutationResult,
  useCreateOrUpdateCompanyCandidateLoginMutation,
  useCreateOrUpdateCompanyCandidateMutation,
} from "@generated/graphql";
import { useContext } from "react";
import { CompanyContext } from "context/companyContext";
import { CandidateType, InfoInputs, IUseTrialSubmitParams } from "../types";
import useUpdateTrial from "./useUpdateTrial";

type UpdateTelegramCompanyCandidateResult = NonNullable<
  CreateOrUpdateCompanyCandidateMutationResult["data"]
>["createOrUpdateCompanyCandidate"];
type UpdateLoginCompanyCandidateResult = NonNullable<
  CreateOrUpdateCompanyCandidateLoginMutationResult["data"]
>["createOrUpdateCompanyCandidateLogin"];

const useUpdateCandidate = ({ setIsEditing }: IUseTrialSubmitParams) => {
  const { addToast } = useContext(ToastContext);
  const { currentCompanyId } = useContext(CompanyContext);

  const showCandidateSaveError = () => addToast({ type: ToastTypeEnum.ERROR, text: "Ошибка сохранения кандидата" });

  const [updateCompanyCandidate, { loading }] = useCreateOrUpdateCompanyCandidateMutation();

  const [updateCompanyCandidateLogin, { loading: updateCompanyCandidateLoginLoading }] =
    useCreateOrUpdateCompanyCandidateLoginMutation();

  const updateCompanyCandidateLoading = loading || updateCompanyCandidateLoginLoading;

  const { updateTrialLoading, onUpdateTrial } = useUpdateTrial();

  const handleCandidateResponse = (
    candidateRes: UpdateLoginCompanyCandidateResult | UpdateTelegramCompanyCandidateResult | undefined,
    formData: InfoInputs
  ) => {
    const candidateId = candidateRes?.id;
    if (candidateId) {
      onUpdateTrial({ formData, candidateId, setIsEditing });
    } else {
      showCandidateSaveError();
    }
  };

  const onUpdateCompanyCandidate = (formData: InfoInputs) => {
    const isTelegramCandidate = formData.candidateType === CandidateType.Telegram;

    if (isTelegramCandidate) {
      updateCompanyCandidate({
        variables: {
          input: {
            id: formData.candidate.id,
            email: formData.candidate.email,
            companyId: currentCompanyId || "",
            fullName: formData.candidate.fullName?.fullName || "",
            organization: formData.candidate?.organization,
            position: formData.candidate?.position,
            grade: formData.candidate?.grade?.id,

            telegramUsername: formData.candidate.telegramUsername?.telegramUsername?.replaceAll("@", "") || "",
          },
        },
      })
        .then((candidateRes) => handleCandidateResponse(candidateRes.data?.createOrUpdateCompanyCandidate, formData))
        .catch(() => showCandidateSaveError());
    } else {
      updateCompanyCandidateLogin({
        variables: {
          input: {
            id: formData.candidate.id,
            email: formData.candidate.email,
            companyId: currentCompanyId || "",
            fullName: formData.candidate.fullName?.fullName || "",
            organization: formData.candidate?.organization,
            position: formData.candidate?.position,
            grade: formData.candidate?.grade?.id,
            login: formData.candidate.login?.login || "",
            password: formData.password || "",
          },
        },
      })
        .then((candidateRes) =>
          handleCandidateResponse(candidateRes.data?.createOrUpdateCompanyCandidateLogin, formData)
        )
        .catch(() => showCandidateSaveError());
    }
  };

  return { onUpdateCompanyCandidate, updateCompanyCandidateLoading, updateTrialLoading };
};

export default useUpdateCandidate;
