import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { Trial_Status } from "@generated/graphql";

export const STATUS_TITLE = {
  [Trial_Status.Canceled]: "Отменено",
  [Trial_Status.Created]: "Создано",
  [Trial_Status.Received]: "Получено",
  [Trial_Status.Started]: "Запущено",
  [Trial_Status.Completed]: "Завершено",
};

const TestingStatus = ({ status }: { status: Trial_Status }) => (
  <Stack
    direction='row'
    alignItems='center'
    spacing={1}
    sx={{
      borderRadius: 1.5,
      backgroundColor: "base.50",
      color: "text.primary",
      px: "10px",
      py: "6px",
      width: 130,
    }}>
    <Box
      sx={{
        backgroundColor: `status.${status.toLowerCase()}`,
        width: 12,
        height: 12,
        borderRadius: "50%",
      }}
    />
    <Typography variant='body16rg'>{STATUS_TITLE[status]}</Typography>
  </Stack>
);

export default TestingStatus;
