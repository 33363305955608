import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useIsCreate from "hooks/useIsCreate";
import CircularLoading from "components/CircularLoading";
import { Competency_Map_Status, useCompetencyMapOptionsQuery, useTrialInfoQuery } from "@generated/graphql";
import Box from "@mui/material/Box";
import SaveEditButtons from "components/buttons/SaveEditButtons";
import { useForm } from "react-hook-form";
import { Grid, Stack } from "@mui/material";
import Form from "components/form/Form";
import TextFieldControl from "components/form/TextFieldControl";
import TrialHistory from "pages/Testing/view/components/InfoTab/components/TrialHistory";
import SelectCandidateType from "pages/Testing/view/components/InfoTab/components/SelectCandidateType";
import CandidateInfo from "./components/CandidateInfo";
import useInitTrialForm from "./hooks/useInitTrialForm";
import useUpdateCandidate from "./hooks/useUpdateCandidate";
import CompetencyMapInfo from "./components/CompetencyMapInfo";
import TrialInfo from "./components/TrialInfo";
import { InfoInputs } from "./types";

const InfoTab = () => {
  const { trialId } = useParams();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState<boolean>(isCreate);

  const { data, loading } = useTrialInfoQuery({
    variables: {
      trialId: trialId!,
    },
    skip: !trialId,
  });

  const { data: competencyMapsData, loading: competencyMapsLoading } = useCompetencyMapOptionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        status: Competency_Map_Status.Approved,
      },
    },
  });

  const trial = data?.trial;
  const competencyMaps = competencyMapsData?.competencyMaps.data;

  const initFormData = useInitTrialForm(trial, competencyMaps);

  const form = useForm<InfoInputs>({
    values: initFormData,
  });
  const { handleSubmit, reset, getValues } = form;

  const { onUpdateCompanyCandidate, updateCompanyCandidateLoading, updateTrialLoading } = useUpdateCandidate({
    setIsEditing,
  });

  const resetCandidateFields = () => {
    reset({
      ...getValues(),
      candidate: {
        email: "",
        phoneNumber: "",
        organization: "",
        position: "",
        grade: null,
        telegramUsername: null,
        login: null,
        fullName: null,
        id: null,
      },
    });
  };

  if (loading || competencyMapsLoading) return <CircularLoading />;

  return (
    <Box sx={{ py: 2 }}>
      <Form form={form}>
        <Grid
          container
          rowSpacing={2.5}>
          <Grid
            container
            item
            xs={12}
            spacing={2.5}>
            <Grid
              item
              xs={12}
              md={6}>
              <Stack spacing={2}>
                <SelectCandidateType
                  resetCandidateFields={resetCandidateFields}
                  isEditing={isEditing}
                />
                <CandidateInfo
                  form={form}
                  isEditing={isEditing}
                  resetCandidateFields={resetCandidateFields}
                />
                <TextFieldControl
                  name='comment'
                  label='Комментарий к тестированию'
                  minRows={6}
                  disabled={!isEditing}
                  multiline
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <Stack spacing={2}>
                <CompetencyMapInfo
                  form={form}
                  competencyMaps={competencyMaps}
                  isEditing={isEditing}
                  competencyMapsLoading={competencyMapsLoading}
                />
                <TrialInfo form={form} />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}>
              <SaveEditButtons
                isEdited={isEditing}
                onSubmit={handleSubmit(onUpdateCompanyCandidate)}
                setIsEdited={setIsEditing}
                loading={updateTrialLoading || updateCompanyCandidateLoading}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}>
            <TrialHistory trial={trial} />
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default InfoTab;
