import { useContext } from "react";
import { Badge, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { UseFieldArrayAppend } from "react-hook-form";
import { Competency_Map_Status, CompetencyMapQuery, useUpdateStatusCompetencyMapMutation } from "@generated/graphql";
import useSetTabTitle from "hooks/useSetTabTitle";
import useCreateTesting from "hooks/useCreateTesting";
import { CompanyContext } from "context/companyContext";
import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import { COMPETENCY_MAP_STATUSES } from "constants/global";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import DownloadIcon from "assets/icons/DownloadIcon";
import { CompetencyMapInputs } from "./types";

interface IHeaderProps {
  competencyMap: CompetencyMapQuery["competencyMap"];
  append: UseFieldArrayAppend<CompetencyMapInputs, "knowledgeBlocks">;
  isCreate: boolean;
  isEditable: boolean;
}

const Header = ({ competencyMap, append, isCreate, isEditable }: IHeaderProps) => {
  const hideDownloadButton = true; // TODO: скрываем, пока не будет готов бэк
  const { createTesting } = useCreateTesting({ competencyMap });
  const { addToast } = useContext(ToastContext);
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const createAssessmentUnavailableText = generateUnavailableText(
    isCreateAvailable,
    currentCompany,
    "создания тестирования"
  );
  const [updateMapStatus] = useUpdateStatusCompetencyMapMutation();

  const title = `Карта компетенций ${competencyMap?.title ? ` «${competencyMap.title}»` : ""}`;
  useSetTabTitle(title, competencyMap?.title || "Новая карта компетенций");

  const handleAddBlock = () => {
    append({ title: "", knowledgeBlockCompetences: undefined });
  };

  const handleChangeStatus = (event: SelectChangeEvent<Competency_Map_Status>) => {
    if (!competencyMap?.id) return;

    updateMapStatus({
      variables: {
        input: {
          id: competencyMap.id,
          status: event.target.value as Competency_Map_Status,
        },
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS });
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const handleDownloadXlsxMap = () => {};

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      mb={2.5}>
      <Typography variant='h28'>{title}</Typography>
      <Stack
        direction='row'
        spacing={2}
        alignItems='flex-start'>
        <ButtonWithPermissions
          buttonText='Добавить компетенцию'
          sx={{ backgroundColor: "white", color: "text.primary", "&:hover": { color: "white" } }}
          onClick={handleAddBlock}
          disabled={!isEditable}
        />
        {!isCreate && (
          <>
            <Select
              sx={{ minWidth: 186 }}
              value={competencyMap?.status || undefined}
              onChange={handleChangeStatus}
              inputProps={{
                sx: { paddingLeft: 4 },
              }}>
              {COMPETENCY_MAP_STATUSES.map((statusData) => (
                <MenuItem
                  key={`competency-map-status-${statusData.value}`}
                  value={statusData.value}
                  sx={{ paddingLeft: 4 }}>
                  <Badge
                    color={statusData.color}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    variant='dot'
                    slotProps={{
                      badge: {
                        style: {
                          top: "50%",
                          left: -12,
                          minWidth: 14,
                          height: 14,
                          borderRadius: "100%",
                        },
                      },
                    }}>
                    {statusData.label}
                  </Badge>
                </MenuItem>
              ))}
            </Select>
            {!hideDownloadButton && (
              <IconButton onClick={handleDownloadXlsxMap}>
                <DownloadIcon />
              </IconButton>
            )}
            <ButtonWithPermissions
              buttonText='Создать тестирование'
              onClick={createTesting}
              tooltipText={createAssessmentUnavailableText}
              disabled={competencyMap?.status !== Competency_Map_Status.Approved}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;
