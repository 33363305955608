import React, { useContext } from "react";
import filterFields from "pages/CompetencyMaps/list/filterFields";
import CompetencyMapCard from "pages/CompetencyMaps/list/CompetencyMapCard";
import { CompetencyMapsQuery, useCompetencyMapsQuery } from "@generated/graphql";
import ListLayout from "layout/ListLayout";
import useSetTabTitle from "hooks/useSetTabTitle";
import { CompanyContext } from "context/companyContext";
import { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import UploadTemplate from "pages/CompetencyMaps/list/components/UploadTemplate";

type CompetencyMapItem = NonNullable<CompetencyMapsQuery["competencyMaps"]["data"]>[number];

const CompetencyMapsList = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const { data, loading, error, refetch } = useCompetencyMapsQuery({
    variables: {
      page: 0,
      limit: 0,
    },
  });

  const list = data?.competencyMaps.data;

  const createUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, "создания карт компетенций");

  useSetTabTitle("Карты компетенций");

  return (
    <ListLayout<CompetencyMapItem>
      buttons={<UploadTemplate />}
      createUnavailableText={createUnavailableText}
      title='Карты компетенций'
      filters={filterFields}
      CardComponent={CompetencyMapCard}
      list={list || []}
      loading={loading}
      error={error}
      refetch={refetch}
      totalCount={data?.competencyMaps.totalCount}
      emptyPlaceholderText='Пока нет карт компетенций'
    />
  );
};

export default CompetencyMapsList;
