import React from "react";
import { createBrowserRouter } from "react-router-dom";
import CompetencyMapsList from "pages/CompetencyMaps/list";
import KnowledgeBlocksList from "pages/KnowledgeBlocks/list";
import TestingList from "pages/Testing/list";
import CompetencyMapView from "pages/CompetencyMaps/view";
import KnowledgeBlockView from "pages/KnowledgeBlocks/view";
import SkillView from "pages/Skill/view";
import TestingView from "pages/Testing/view";
import Login from "pages/Login";
import ROUTES from "constants/routes";
import UserRegister from "pages/Register/UserRegister";
import CompanyRegister from "pages/Register/CompanyRegister";
import CompanyPage from "pages/CompanyPage";
import ProfilePage from "pages/Profile";
import MainLayout from "./layout/MainLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.USER_REGISTER,
        element: <UserRegister />,
      },
      {
        path: ROUTES.COMPANY_REGISTER,
        element: <CompanyRegister />,
      },
      {
        path: ROUTES.COMPANY,
        element: <CompanyPage />,
      },
      {
        path: ROUTES.PROFILE,
        element: <ProfilePage />,
      },
      {
        path: ROUTES.COMPETENCE_MAPS,
        children: [
          {
            index: true,
            element: <CompetencyMapsList />,
          },
          {
            path: "view/:mapId",
            element: <CompetencyMapView />,
          },
          {
            path: "create",
            element: <CompetencyMapView />,
          },
        ],
      },
      {
        path: ROUTES.KNOWLEDGE_BLOCKS,
        children: [
          {
            index: true,
            element: <KnowledgeBlocksList />,
          },
          {
            path: "view/:knowledgeBlockId",
            element: <KnowledgeBlockView />,
          },
          {
            path: "create",
            element: <KnowledgeBlockView />,
          },
        ],
      },
      {
        path: ROUTES.TESTING,
        children: [
          {
            index: true,
            element: <TestingList />,
          },
          {
            path: "view/:trialId",
            element: <TestingView />,
          },
          {
            path: "create",
            element: <TestingView />,
          },
        ],
      },
      {
        path: ROUTES.SKILL,
        children: [
          {
            path: "view/:skillId",
            element: <SkillView />,
          },
          {
            path: "create",
            element: <SkillView />,
          },
        ],
      },
    ],
  },
]);

export default router;
