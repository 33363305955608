import { InputType } from "components/generators/inputsGenerator";
import { FilterType } from "components/generators/filtersGenerator";
import { formatForFilter } from "helpers/date";
import { generateOptions } from "helpers/index";
import { STATUS_TITLE } from "components/TestingStatus";
import { FilterItem } from "layout/ListLayout/types";
import { IAutocompleteValue } from "components/inputs/Autocomplete/types";
import VALIDATION from "helpers/validation";

const filterFields: FilterItem[] = [
  {
    name: "completedAt",
    label: "Дата завершения тестирования",
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value: string) => formatForFilter(value, "start"),
  },
  {
    name: "competencyMapTitle",
    label: "Карта компетенций",
    inputType: InputType.TEXT,
    rules: VALIDATION.competencyMapTitle,
  },
  {
    name: "candidateFullName",
    label: "Кандидат",
    inputType: InputType.TEXT,
    rules: VALIDATION.userName,
  },
  {
    name: "status",
    label: "Статус",
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: generateOptions(STATUS_TITLE),
    customFilter: (value: IAutocompleteValue) => value.id,
  },
];

export default filterFields;
