import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import useIsCreate from "hooks/useIsCreate";
import ROUTES from "constants/routes";
import { useCreateOrUpdateTrialMutation } from "@generated/graphql";
import { UpdateTrialParams } from "../types";

const useUpdateTrial = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const isCreate = useIsCreate();
  const { trialId } = useParams();

  const toggleToastOnError = (text?: string) => addToast({ type: ToastTypeEnum.ERROR, text });
  const toggleToastOnSuccess = (text?: string) => addToast({ type: ToastTypeEnum.SUCCESS, text });
  const navigateToCreatedTesting = (id: string) => navigate(`/${ROUTES.TESTING}/view/${id}`);

  const [updateTrial, { loading: updateTrialLoading }] = useCreateOrUpdateTrialMutation();

  const onUpdateTrial = ({ formData, candidateId, setIsEditing }: UpdateTrialParams) => {
    updateTrial({
      variables: {
        input: {
          id: trialId,
          competencyMapId: formData.competencyMap?.id!,
          comment: formData.comment,
          companyCandidateId: candidateId,
        },
      },
    })
      .then((res) => {
        const createdTrialId = res.data?.createOrUpdateTrial.id;
        if (isCreate && createdTrialId) {
          navigateToCreatedTesting(createdTrialId);
        }
        setIsEditing(false);
        toggleToastOnSuccess();
      })
      .catch(() => toggleToastOnError());
  };

  return { updateTrialLoading, onUpdateTrial };
};

export default useUpdateTrial;
