import Box from "@mui/material/Box";
import { Pagination, Stack, Typography } from "@mui/material";
import Select from "components/inputs/Select";
import React, { useMemo } from "react";
import useList, { DEFAULT_LIMIT, ILimit, LIMITS } from "hooks/useList";

const ListPagination = ({ totalCount }: { totalCount: number }) => {
  const { changePage, page, changeLimit, limit } = useList();
  const pageCount = useMemo(() => {
    const limitInt = limit ? +limit : DEFAULT_LIMIT;
    if (totalCount) return Math.ceil(totalCount / +limitInt);
    return 0;
  }, [totalCount, limit]);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box flex={1} />
      <Pagination
        count={pageCount}
        variant='outlined'
        shape='rounded'
        onChange={changePage}
        page={parseInt(page, 10) || 1}
      />
      <Stack
        flex={1}
        justifyContent='end'
        direction='row'
        spacing={1.5}>
        <Typography variant='body14rg'>
          Карточек
          <br /> на странице
        </Typography>
        <Select<ILimit>
          items={LIMITS}
          value={limit || (DEFAULT_LIMIT.toString() as ILimit["value"])}
          onChange={changeLimit}
        />
      </Stack>
    </Box>
  );
};

export default ListPagination;
