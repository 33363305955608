import { Dispatch, RefObject, SetStateAction, useContext } from "react";
import { Card, Stack } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import TextFieldControl from "components/form/TextFieldControl";
import SaveEditButtons from "components/buttons/SaveEditButtons";
import {
  useCreateOrUpdateCompetencyMapMutation,
  useCreateOrUpdateKnowledgeBlockMutation,
  useCreateOrUpdateSkillMutation,
  useDeleteCompetencyMapMutation,
} from "@generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import useIsCreate from "hooks/useIsCreate";
import CheckboxControl from "components/form/CheckboxControl";
import ROUTES from "constants/routes";
import { CompanyContext } from "context/companyContext";
import { ConfirmModalContext } from "context/confirmModalContext";
import VALIDATION from "helpers/validation";
import { cleanSpaceInString } from "helpers/validation/createValidation";
import DatePickerControl from "components/form/DatePickerControl";
import { CompetencyLiteralType, CompetencyMapInputs, KnowledgeBlockCompetencyOption, SkillOption } from "./types";

type InfoProps = {
  form: UseFormReturn<CompetencyMapInputs>;
  isEditable: boolean;
  infoCardRef: RefObject<HTMLDivElement>;
  setIsEditable: Dispatch<SetStateAction<boolean>>;
};

interface KnowledgeBlockCompetencyWithFieldKey extends KnowledgeBlockCompetencyOption {
  fieldKey: CompetencyLiteralType;
}

interface SkillOptionWithFieldKey extends SkillOption {
  fieldKey: CompetencyLiteralType;
  knowledgeBlockId: string;
}

const Info = ({ form, isEditable, infoCardRef, setIsEditable }: InfoProps) => {
  const navigate = useNavigate();
  const isCreate = useIsCreate();

  const { addToast } = useContext(ToastContext);
  const { handleOpen } = useContext(ConfirmModalContext);
  const { currentCompanyId } = useContext(CompanyContext);
  const { mapId } = useParams();

  const {
    handleSubmit,
    formState: { errors },
  } = form;
  const [updateMap, { loading }] = useCreateOrUpdateCompetencyMapMutation();
  const [updateKnowledgeBlock, { loading: knowledgeBlockLoading }] = useCreateOrUpdateKnowledgeBlockMutation();
  const [updateSkill, { loading: skillLoading }] = useCreateOrUpdateSkillMutation();

  const handleUpdateKnowledgeBlocks = async (knowledgeBlocks: NonNullable<CompetencyMapInputs["knowledgeBlocks"]>) => {
    const createdKnowledgeBlocksWithKey = knowledgeBlocks.reduce<KnowledgeBlockCompetencyWithFieldKey[]>(
      (blockAcc, block, blockIndex) => [
        ...blockAcc,
        ...(block.knowledgeBlockCompetences
          ? block.knowledgeBlockCompetences
              .map((competence, competenceIndex) => ({
                ...competence,
                fieldKey:
                  `knowledgeBlocks.${blockIndex}.knowledgeBlockCompetences.${competenceIndex}` as CompetencyLiteralType,
              }))
              .filter((competence) => !competence.id)
          : []),
      ],
      []
    );

    const promises = createdKnowledgeBlocksWithKey.map((block) =>
      updateKnowledgeBlock({
        variables: {
          input: {
            companyId: currentCompanyId!,
            id: block?.id,
            title: cleanSpaceInString(block.title || ""),
            profession: block.profession || "",
          },
        },
      })
        .then((res) => {
          form.setValue(`${block.fieldKey}.id`, res.data?.createOrUpdateKnowledgeBlock.id);
        })
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
    );

    await Promise.all(promises);
  };

  const handleUpdateSkills = async (knowledgeBlocks: NonNullable<CompetencyMapInputs["knowledgeBlocks"]>) => {
    const createdSkillsWithKey = knowledgeBlocks.reduce<SkillOptionWithFieldKey[]>(
      (blockAcc, block, blockIndex) => [
        ...blockAcc,
        ...(block.knowledgeBlockCompetences
          ? block.knowledgeBlockCompetences.reduce<SkillOptionWithFieldKey[]>(
              (competenceAcc, competence, competenceIndex) => [
                ...competenceAcc,
                ...(competence.skills
                  ? competence.skills
                      .map((skill, skillIndex) => ({
                        ...skill,
                        fieldKey:
                          // eslint-disable-next-line max-len
                          `knowledgeBlocks.${blockIndex}.knowledgeBlockCompetences.${competenceIndex}.skills.${skillIndex}` as CompetencyLiteralType,
                        knowledgeBlockId: competence.id!,
                      }))
                      .filter((skill) => !skill.id)
                  : []),
              ],
              []
            )
          : []),
      ],
      []
    );

    const promises = createdSkillsWithKey.map((skill) =>
      updateSkill({
        variables: {
          input: {
            id: skill?.id,
            title: cleanSpaceInString(skill.title),
            knowledgeBlockId: skill.knowledgeBlockId,
          },
        },
      })
        .then((res) => {
          form.setValue(`${skill.fieldKey}.id`, res.data?.createOrUpdateSkill.id);
        })
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
    );

    await Promise.all(promises);
  };

  const onSubmit = async (formData: CompetencyMapInputs) => {
    let { knowledgeBlocks } = formData;

    await handleUpdateKnowledgeBlocks(knowledgeBlocks || []);
    knowledgeBlocks = form.getValues().knowledgeBlocks;

    await handleUpdateSkills(knowledgeBlocks || []);
    knowledgeBlocks = form.getValues().knowledgeBlocks;

    updateMap({
      variables: {
        input: {
          companyId: currentCompanyId!,
          title: cleanSpaceInString(formData.title),
          id: mapId,
          specialization: cleanSpaceInString(formData.specialization),
          comment: formData.comment ? cleanSpaceInString(formData.comment) : undefined,
          competences: knowledgeBlocks!.map((block) => ({
            id: block.id,
            title: block.title!,
            knowledgeBlocksIds: (block.knowledgeBlockCompetences?.map((competence) => competence.id) || []) as string[],
          })),
          isRepositoryCheck: formData.isRepositoryCheck,
        },
      },
    })
      .then((res) => {
        if (isCreate) navigate(`/${ROUTES.COMPETENCE_MAPS}/view/${res.data?.createOrUpdateCompetencyMap.id}`);
        setIsEditable(false);
        addToast({ type: ToastTypeEnum.SUCCESS });
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => {
    const firstError = Object.values(errors)?.[0];
    const errorText = firstError?.message || firstError?.root?.message;
    addToast({ type: ToastTypeEnum.ERROR, text: errorText as string | undefined });
  };

  const [deleteMap] = useDeleteCompetencyMapMutation();

  const handleDeleteMap = async () => {
    await deleteMap({
      variables: {
        competencyMapId: mapId!,
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS, text: "Карта компетенций удалена" });
        navigate(`/${ROUTES.COMPETENCE_MAPS}`);
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const handleOpenConfirmDeleteMapModal = () => {
    handleOpen({
      title: "Вы уверены, что хотите удалить карту кометенций",
      actionButtonText: "Удалить",
      handleClick: handleDeleteMap,
    });
  };

  return (
    <Card ref={infoCardRef}>
      <Stack
        spacing={2}
        mb={4}>
        <TextFieldControl
          label='Название'
          name='title'
          disabled={!isEditable}
          rules={{ required: true, ...VALIDATION.competencyMapTitle }}
        />
        <TextFieldControl
          label='Специализация'
          name='specialization'
          disabled={!isEditable}
          rules={{ required: true, ...VALIDATION.competencyMapTitle }}
        />
        <TextFieldControl
          label='Комментарий'
          name='comment'
          multiline
          minRows={4}
          maxRows={4}
          disabled={!isEditable}
        />
        <TextFieldControl
          name='grades'
          label='Грейд'
          disabled
        />
        <CheckboxControl
          label='запрос проверки репозитория'
          name='isRepositoryCheck'
          disabled={!isEditable}
        />
        <DatePickerControl
          name='createdAt'
          label='Дата создания'
          disabled
        />
        <DatePickerControl
          name='updatedAt'
          label='Дата изменения'
          disabled
        />
      </Stack>
      <SaveEditButtons
        deleteText='Удалить карту'
        onDelete={handleOpenConfirmDeleteMapModal}
        isEdited={isEditable}
        onSubmit={handleSubmit(onSubmit, onError)}
        setIsEdited={setIsEditable}
        loading={loading || knowledgeBlockLoading || skillLoading}
      />
    </Card>
  );
};

export default Info;
