import Button from "@mui/material/Button";
import EditIcon from "assets/icons/EditIcon";
import React, { Dispatch, SetStateAction, useContext } from "react";
import useIsCreate from "hooks/useIsCreate";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "assets/icons/DeleteIcon";
import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import { CompanyContext } from "context/companyContext";

type SaveEditButtonsProps = {
  isEdited: boolean;
  onSubmit: () => void;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  deleteText?: string;
  onDelete?: () => void;
};

const SaveEditButtons = ({ isEdited, onSubmit, setIsEdited, loading, deleteText, onDelete }: SaveEditButtonsProps) => {
  const isCreate = useIsCreate();
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  return isEdited ? (
    <>
      {isCreate ? (
        <ButtonWithPermissions
          buttonText='Сохранить'
          onClick={onSubmit}
          tooltipText={generateUnavailableText(isCreateAvailable, currentCompany, "создания")}
          loading={loading}
        />
      ) : (
        <LoadingButton
          loading={loading}
          variant='contained'
          onClick={onSubmit}
          fullWidth
          size='medium'>
          Сохранить
        </LoadingButton>
      )}
      {!isCreate && (
        <>
          <Button
            size='medium'
            sx={{ mt: 1 }}
            variant='outlined'
            color='secondary'
            fullWidth
            onClick={() => setIsEdited(false)}>
            Отменить
          </Button>
          {onDelete && (
            <Button
              size='medium'
              sx={{
                justifyContent: "space-between",
                mt: 4,
                textAlign: "left",
                "&:hover .MuiSvgIcon-root": {
                  color: "primary.main",
                },
              }}
              endIcon={<DeleteIcon sx={{ width: 24, height: 24, color: "base.200" }} />}
              variant='outlined'
              color='secondary'
              fullWidth
              onClick={onDelete}>
              {deleteText}
            </Button>
          )}
        </>
      )}
    </>
  ) : (
    <LoadingButton
      endIcon={<EditIcon />}
      onClick={() => setIsEdited(true)}
      variant='contained'
      loading={loading}
      sx={{
        py: 1,
        px: 2,
        width: "max-content",
        "& .MuiButton-endIcon": { margin: 0 },
      }}
    />
  );
};

export default SaveEditButtons;
