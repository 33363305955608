import { GRADE_OPTIONS } from "constants/global";
import { formatISODate } from "helpers/date";
import { Trial_Status, TrialInfoQuery } from "@generated/graphql";
import { useMemo } from "react";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import {
  CandidateType,
  CompetencyMapOptionList,
  ICandidateItem,
  InfoInputs,
} from "pages/Testing/view/components/InfoTab/types";

const useInitTrialForm = (trial: TrialInfoQuery["trial"], competencyMaps: CompetencyMapOptionList): InfoInputs => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { mapId } = params as unknown as { mapId?: string };

  const competencyMap = mapId ? competencyMaps?.find((map) => map.id === mapId) : trial?.competencyMap;

  const companyCandidate = trial?.companyCandidate;
  const candidateUsername = companyCandidate?.candidateUsername;
  const comment = trial?.comment;
  const trialStatusHistory = trial?.trialStatusHistory;
  const createdAt = trial?.createdAt;
  const completedAt = trial?.completedAt;
  const phoneNumber = candidateUsername?.candidate?.phoneNumber?.replace("+", "") || "";

  const getTelegramUserName = () => {
    const telegramUsername = candidateUsername?.telegramUsername;
    if (telegramUsername) {
      return `@${telegramUsername}`;
    }
    return "";
  };

  const candidateData: ICandidateItem | null = companyCandidate
    ? {
        id: companyCandidate.id || "",
        fullName: companyCandidate.fullName || "",
        telegramUsername: getTelegramUserName(),
        phoneNumber,
        email: companyCandidate.email,
        position: companyCandidate.position,
        organization: companyCandidate.organization,
        grade: companyCandidate.grade,
        login: companyCandidate.candidateUsername?.login,
      }
    : null;

  const startedAt = trialStatusHistory?.find((history) => history.newStatus === Trial_Status.Started)?.createdAt;
  const login = candidateUsername?.login;
  let assessmentLink;
  if (trial?.id) {
    assessmentLink = login
      ? `${process.env.REACT_APP_ASSESSMENT_URL}/login`
      : `https://t.me/${process.env.REACT_APP_BOT_URL}?start=${trial?.id}`;
  }

  return {
    candidateType: login ? CandidateType.Login : CandidateType.Telegram,
    id: trial?.id || "",
    candidate: {
      id: companyCandidate?.id || "",
      fullName: candidateData,
      grade: GRADE_OPTIONS?.find((grade) => companyCandidate?.grade === grade.id) || null,
      telegramUsername: candidateData,
      phoneNumber: candidateUsername?.candidate?.phoneNumber?.replace("+", "") || "",
      email: companyCandidate?.email || "",
      organization: companyCandidate?.organization,
      position: companyCandidate?.position,
      login: candidateData,
    },
    comment,
    createdAt: createdAt ? formatISODate(createdAt, "dd.MM.yyyy HH:mm") : "",
    completedAt: completedAt ? formatISODate(completedAt, "dd.MM.yyyy HH:mm") : "",
    startedAt: startedAt ? formatISODate(startedAt, "dd.MM.yyyy HH:mm") : "",
    competencyMap: {
      id: competencyMap?.id || "",
      title: competencyMap?.title || "",
      comment: competencyMap?.comment || "",
      grades: competencyMap?.grades?.join(", ") || "",
    },
    link: assessmentLink,
  };
};

export default useInitTrialForm;
