import { KnowledgeBlocks } from "pages/Testing/view/components/ResultTab";

type FormattedSkill = {
  id: string;
  title: string;
  score: number | null | undefined;
};

type FormattedCompetence = {
  id: string;
  title: string;
  skills: FormattedSkill[];
};

type FormattedKnowledgeBlock = {
  id: string;
  title: string;
  competences: FormattedCompetence[];
};

const getRecommendedThemes = (knowledgeBlocks: KnowledgeBlocks): Array<FormattedKnowledgeBlock> => {
  if (!knowledgeBlocks) return [];
  return knowledgeBlocks
    .map((block) => {
      const competences = (block.knowledgeBlockCompetences || [])
        .map((competency) => {
          const skills = (competency.knowledgeBlock.skills || [])
            .filter((skill) => !skill.trialAnswer?.score || skill.trialAnswer.score < 40)
            .map((skill) => ({
              id: skill.id,
              title: skill.title,
              score: skill.trialAnswer?.score,
            }));

          return {
            id: competency.knowledgeBlock.id,
            title: competency.knowledgeBlock.title,
            skills,
          };
        })
        .filter((competence) => competence.skills.length > 0);

      return {
        id: block.id,
        title: block.title,
        competences,
      };
    })
    .filter((block) => block.competences.length > 0);
};

export default getRecommendedThemes;
