import React from "react";
import { KnowledgeBlocks } from "pages/Testing/view/components/ResultTab";
import { Typography, Grid } from "@mui/material";
import getLowScoreSkills from "pages/Testing/view/components/ResultTab/helpers/getRecommendedThemes";
import CollapsedBlock from "../CollapsedBlock";

export enum Decision {
  correct = "correct",
  almost_correct = "almost_correct",
  partial_correct = "partial_correct",
  partial_incorrect = "partial_incorrect",
  incorrect = "incorrect",
}

export type RecommendationBlockProps = {
  knowledgeBlocks: KnowledgeBlocks;
};

const RecommendationBlock = ({ knowledgeBlocks }: RecommendationBlockProps) => {
  const lowScoreSkills = getLowScoreSkills(knowledgeBlocks);

  return (
    <CollapsedBlock title='Темы, рекомендованные к повторению'>
      <Grid
        item
        xs={12}
        lg={6}>
        {lowScoreSkills?.length ? (
          <ul>
            {lowScoreSkills?.map((knowledgeBlock) => (
              <li key={`recommendation-block-${knowledgeBlock.id}`}>
                {knowledgeBlock.title}
                <ul>
                  {knowledgeBlock.competences.map((competence) => (
                    <li key={`recommendation-block-${knowledgeBlock.id}-competence-${competence.id}`}>
                      <Typography variant='body14rg'>{competence.title}</Typography>
                      <ul>
                        {competence.skills.map((skill) => (
                          <li
                            key={`recommendation-block-${knowledgeBlock.id}-competence-${competence.id}-skill-${skill.id}`}>
                            <Typography variant='body14rg'>{skill.title}</Typography>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant='body14rg'>Кандидат набрал по всем темам более 40 баллов</Typography>
        )}
      </Grid>
    </CollapsedBlock>
  );
};

export default RecommendationBlock;
