import { KnowledgeBlocks } from "pages/Testing/view/components/ResultTab";

type ExpandedTrialAnswer = {
  blockTitle: string;
  knowledgeBlockTitle: string;
  skillTitle: string;
  score?: number | null;
  time?: number | null;
  questionText: string;
  candidateAnswer: string | null | undefined;
  criterionAssessments:
    | Array<{
        criteria: string;
        decision: string;
      }>
    | null
    | undefined;
};

const getTrialAnswerList = (knowledgeBlocks: KnowledgeBlocks): Array<ExpandedTrialAnswer> => {
  const result: Array<ExpandedTrialAnswer> = [];

  if (!knowledgeBlocks) return result;

  knowledgeBlocks.forEach((block) => {
    const blockTitle = block.title;

    (block.knowledgeBlockCompetences || []).forEach((knowledgeBlockCompetence) => {
      const knowledgeBlockTitle = knowledgeBlockCompetence.knowledgeBlock.title;

      (knowledgeBlockCompetence.knowledgeBlock.skills || []).forEach((skill) => {
        const skillTitle = skill.title;

        const { trialAnswer } = skill;
        if (trialAnswer) {
          result.push({
            blockTitle,
            knowledgeBlockTitle,
            skillTitle,
            score: trialAnswer.score,
            time: trialAnswer.time,
            questionText: trialAnswer.question.text,
            criterionAssessments: trialAnswer.criterionAssessments,
            candidateAnswer: trialAnswer.answer,
          });
        }
      });
    });
  });

  return result;
};

export default getTrialAnswerList;
