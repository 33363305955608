import { endOfDay, format, fromUnixTime, isValid, parse, parseISO, startOfDay } from "date-fns";

const formatFromStringToDate = (date: string) => parse(date, "yyyy-MM-dd", new Date());
const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const checkIsDateValid = (date: any) => {
  if (isValid(date)) return true;
  const parsedDate = formatFromStringToDate(date);
  return isValid(parsedDate);
};

const formatISODate = (dateISO: string, dateFormat: string = "dd.MM.yyyy") => format(parseISO(dateISO), dateFormat);

const formatForFilter = (date: string, partOfDay?: string) =>
  partOfDay !== "end"
    ? format(startOfDay(formatFromStringToDate(date)), ISO_FORMAT)
    : format(endOfDay(formatFromStringToDate(date)), ISO_FORMAT);

const fromMillisecondsToDate = (milliseconds: number): Date => {
  const seconds = Math.floor(milliseconds / 1000);
  return fromUnixTime(seconds);
};

export { checkIsDateValid, formatISODate, formatForFilter, fromMillisecondsToDate };
