import React from "react";
import Box from "@mui/material/Box";
import TabLayout from "layout/TabLayout";
import InfoTab from "pages/Testing/view/components/InfoTab";
import ResultTab from "pages/Testing/view/components/ResultTab";
import useIsCreate from "hooks/useIsCreate";
import { useParams } from "react-router-dom";
import { Trial_Status, useTrialHeaderQuery } from "@generated/graphql";
import CircularLoading from "components/CircularLoading";
import Header from "./components/Header";

const TestingView = () => {
  const isCreate = useIsCreate();
  const { trialId } = useParams();
  const { data, loading } = useTrialHeaderQuery({
    variables: {
      trialId: trialId!,
    },
    skip: !trialId,
  });
  const TABS = [
    {
      title: "Общая информация",
      id: "info",
      component: <InfoTab />,
    },
    {
      title: "Результаты",
      id: "result",
      component: <ResultTab />,
      disabled: isCreate || data?.trial?.status !== Trial_Status.Completed,
    },
  ];

  return (
    <Box>
      {loading ? <CircularLoading /> : <Header trial={data?.trial} />}
      <TabLayout
        tabs={TABS}
        defaultTab='info'
      />
    </Box>
  );
};
export default TestingView;
