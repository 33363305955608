import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import Form from "components/form/Form";
import { useFieldArray, useForm } from "react-hook-form";
import TextFieldControl from "components/form/TextFieldControl";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import {
  Language,
  Question_Type,
  useCreateOrUpdateQuestionMutation,
  useQuestionSubtypesQuery,
} from "@generated/graphql";
import AutocompleteControl from "components/form/AutocompleteControl";
import Box from "@mui/material/Box";
import { Add, Close } from "@mui/icons-material";
import { formatNumber } from "helpers/index";
import { GRADE_OPTIONS, QUESTION_TYPES } from "constants/global";
import { useParams } from "react-router-dom";
import SelectControl from "components/form/SelectControl";
import { cleanSpaceInString } from "helpers/validation/createValidation";
import { QUESTION_LANGUAGE_OPTIONS } from "constants/language";
import { UpdateQuestionModalForm, UpdateQuestionModalProps } from "../types/skill";

const UpdateQuestionModal = ({
  isOpen,
  onClose,
  isCreate,
  skill,
  question,
  refetchSkill,
}: UpdateQuestionModalProps) => {
  const { skillId } = useParams();
  const { addToast } = useContext(ToastContext);
  const title = isCreate ? "Создание вопроса" : "Редактирование вопроса";
  const isConfirm = question?.confirmation;

  const { data: questionSubtypesData, loading: questionSubtypesLoading } = useQuestionSubtypesQuery();

  const questionSubtypes = questionSubtypesData?.questionSubtypes;

  const [updateQuestion] = useCreateOrUpdateQuestionMutation();

  const getRecommendedTime = () => {
    if (question?.recommendedTime) {
      const recommendedTimeToMinutes = Math.ceil(question.recommendedTime / 60);
      return formatNumber(["минута", "минуты", "минут"], recommendedTimeToMinutes);
    }
    return undefined;
  };

  const form = useForm<UpdateQuestionModalForm>({
    defaultValues: {
      knowledgeBlock: {
        title: skill?.knowledgeBlock.title,
      },
      title: skill?.title,
      time: question ? question.time / 60 : 1,
      criteria: question?.criterion || [],
      recommendedTime: getRecommendedTime(),
      questionText: question?.text,
      grade: GRADE_OPTIONS.find((grade) => grade.id === question?.grade),
      type: QUESTION_TYPES?.find((type) => type.value === question?.type)?.value,
      questionSubtype: question?.subtype,
      language: question?.language || Language.Ru,
    },
  });

  const { handleSubmit, setValue, control, watch } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "formId",
    name: "criteria",
  });

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const onSubmit = (formData: UpdateQuestionModalForm) => {
    const time = typeof formData.time === "string" ? parseInt(formData.time, 10) : formData.time;
    updateQuestion({
      variables: {
        input: {
          criterion: formData.criteria.map((c) => cleanSpaceInString(c)),
          grade: formData.grade.id,
          id: question?.id,
          text: cleanSpaceInString(formData.questionText),
          time: time * 60,
          skillId: skillId!,
          type: formData.type,
          subtype: formData.questionSubtype,
          language: formData.language,
        },
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS });
        refetchSkill();
        onClose();
      })
      .catch(onError);
  };

  const onChangeMinutes = (value: number) => {
    setValue("time", value >= 1 ? value : 1);
  };

  const questionTypeWatch = watch("type");

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='sm'>
      <DialogTitle
        variant='h24'
        textAlign='center'>
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        {!isCreate && isConfirm && (
          <Alert sx={{ mb: 3, px: 1.5, py: 1 }}>
            При&nbsp;изменении подтвержденного вопроса&nbsp;— вопрос будет перенаправлен в&nbsp;список неподтвержденных
            вопросов. Для&nbsp;использования вопроса в&nbsp;рамках тестирований необходимо подтвердить данный вопрос.
          </Alert>
        )}
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl
              name='KnowledgeBlock.title'
              disabled
              label='Блок знаний'
            />
            <TextFieldControl
              name='title'
              disabled
              label='навык'
            />
            <AutocompleteControl
              options={GRADE_OPTIONS}
              name='grade'
              label='Грейд'
              rules={{
                required: true,
              }}
            />
            <SelectControl
              name='type'
              items={QUESTION_TYPES}
              sx={{ backgroundColor: "bgSwitch" }}
              label='Тип вопроса'
              rules={{
                required: true,
              }}
            />
            {questionTypeWatch === Question_Type.Practical && (
              <AutocompleteControl
                name='questionSubtype'
                options={questionSubtypes || []}
                loading={questionSubtypesLoading}
                label='Подтип'
                rules={{
                  validate: (value, formValues) => {
                    const isPracticalQuestion = formValues.questionType === Question_Type.Practical;
                    return !(isPracticalQuestion && !value);
                  },
                }}
              />
            )}
            <SelectControl
              name='language'
              items={QUESTION_LANGUAGE_OPTIONS}
              sx={{ backgroundColor: "bgSwitch" }}
              label='Язык вопроса'
              rules={{
                required: true,
              }}
            />
            <TextFieldControl
              name='questionText'
              label='Текст вопроса'
              multiline
              rows={4}
              rules={{ required: true }}
            />
            <TextFieldControl
              rules={{
                validate: (value: number) => (!!value && !Number.isNaN(value) ? value > 0 : false),
              }}
              name='time'
              label='Время ответа'
              handleChange={(value: string, onChange) => onChange(value.replace(/\D/g, "").trim())}
              type='number'
              onNumberValueChange={onChangeMinutes}
            />
            {!isCreate && (
              <TextFieldControl
                name='recommendedTime'
                label='Рекомендуемое время ответа'
                disabled
              />
            )}
            <Box sx={{ backgroundColor: "bgSwitch", p: 2 }}>
              <Stack spacing={2}>
                <Typography
                  variant='body14rg'
                  color='text.secondary'>
                  Критерии оценки ответа
                </Typography>
                {fields.map((field, index) => (
                  <TextFieldControl
                    multiline
                    rows={2}
                    endIcon={
                      fields?.length > 1 && (
                        <IconButton onClick={() => remove(index)}>
                          <Close sx={{ color: "base.200" }} />
                        </IconButton>
                      )
                    }
                    key={field.formId}
                    name={`criteria.${index}`}
                    rules={{ required: true }}
                    placeholder='Текст критерия оценки'
                    sx={{
                      "& > .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputAdornment-root ": {
                        alignItems: "flex-end",
                        height: 24,
                      },
                    }}
                  />
                ))}
                <Button
                  startIcon={<Add />}
                  sx={({ typography }) => ({
                    px: 0,
                    justifyContent: "flex-start",
                    color: "base.200",
                    ...typography.body14rg,
                  })}
                  onClick={() => append("")}>
                  критерий оценки
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}>
          Отмена
        </Button>
        <LoadingButton
          variant='contained'
          onClick={handleSubmit(onSubmit, onError)}>
          {isCreate ? "Создать" : "Сохранить"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateQuestionModal;

UpdateQuestionModal.defaultProps = {
  isCreate: false,
  question: undefined,
};
