import AutocompleteControl from "components/form/AutocompleteControl";
import TextFieldControl from "components/form/TextFieldControl";
import { UseFormReturn } from "react-hook-form";
import { AutocompleteInputChangeReason } from "@mui/material";
import { CompetencyMapOption, CompetencyMapOptionList, ICompetencyMapOption, InfoInputs } from "../types";

interface ICompetencyMapInfoProps {
  competencyMaps: CompetencyMapOptionList;
  isEditing: boolean;
  competencyMapsLoading: boolean;
  form: UseFormReturn<InfoInputs>;
}

const CompetencyMapInfo = ({ competencyMaps, isEditing, competencyMapsLoading, form }: ICompetencyMapInfoProps) => {
  const { setValue, reset, getValues } = form;

  const onCompetencyMapChange = (data: CompetencyMapOption | ICompetencyMapOption | null) => {
    if (data && typeof data === "object" && "comment" in data) {
      setValue("competencyMap.comment", data.comment || "");
      setValue("competencyMap.grades", data?.grades?.join(", "));
    }
    return data ? { id: data.id, title: data.title } : data;
  };

  const resetCompetencyMapFields = () => {
    reset({
      ...getValues(),
      competencyMap: {
        id: "",
        title: "",
        grades: "",
        comment: "",
      },
    });
  };

  const onAutocompleteInputChange = (reason: AutocompleteInputChangeReason) => {
    if (reason === "clear") {
      resetCompetencyMapFields();
    }
  };

  return (
    <>
      <AutocompleteControl<CompetencyMapOption, false, false, false, ICompetencyMapOption>
        options={competencyMaps || []}
        name='competencyMap'
        optionName='title'
        label='Карта компетенций'
        disabled={!isEditing}
        loading={competencyMapsLoading}
        rules={{ required: true }}
        formatDataBeforeOnChange={onCompetencyMapChange}
        onInputChange={(_, inputValue, reason) => onAutocompleteInputChange(reason)}
      />
      <TextFieldControl
        name='competencyMap.comment'
        label='Комментарий к карте компетенций'
        minRows={7}
        multiline
        disabled
      />
      <TextFieldControl
        name='competencyMap.grades'
        label='Грейд карты компетенций'
        disabled
      />
    </>
  );
};

export default CompetencyMapInfo;
