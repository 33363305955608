import { INPUT_DEFAULT_VALUE, InputType } from "components/generators/inputsGenerator";
import qs, { ParsedQs } from "qs";
import { format, isValid } from "date-fns";
import _ from "lodash";
import { FilterItem } from "layout/ListLayout/types";
import { SetURLSearchParams } from "react-router-dom";
import { UseFormReset } from "react-hook-form";
import { cleanSpaceInString } from "helpers/validation/createValidation";

export enum FilterType {
  CUSTOM_FILTER = "CustomFilter",
}

export const generateQueryOnSubmit = (
  data: Record<string, any>,
  filters: FilterItem[],
  setSearchParams: SetURLSearchParams
) => {
  const params = {};
  filters.forEach((filter) => {
    const { inputType, name } = filter;
    const filterValue = _.get(data, name);
    const setParam = (newData: string | object) => _.set(params, name, newData);
    if (!filterValue) return;
    switch (inputType) {
      case InputType.DATE: {
        if (isValid(filterValue)) setParam(format(filterValue, "yyyy-MM-dd"));
        else setParam(filterValue);
        break;
      }
      default: {
        setParam(typeof filterValue === "string" ? cleanSpaceInString(filterValue) : filterValue);
        break;
      }
    }
  });
  setSearchParams(qs.stringify(params));
};

// чистит инпуты и query параметры
export const onClearFilter = (filters: FilterItem[], reset: UseFormReset<any>, setSearchParams: SetURLSearchParams) => {
  setSearchParams({});
  const params = {};
  filters.forEach((filter: any) => {
    const { name, defaultValue, multiple } = filter;
    const inputType = filter.inputType as InputType;
    _.set(params, name, multiple ? [] : defaultValue || INPUT_DEFAULT_VALUE[inputType]);
  });
  reset(params);
};

// заполняет форму фильтров из query параметров
export const onUseEffect = (filters: FilterItem[], reset: UseFormReset<any>, query: ParsedQs) => {
  const params = {};
  filters.forEach((filter: any) => {
    const inputType = filter.inputType as InputType;

    const isAutocomplete = inputType === InputType.AUTOCOMPLETE;
    const existingValue = _.get(query, filter.name);

    const setParam = (newData: any) => _.set(params, filter.name, newData);

    let inputValue;
    if (!isAutocomplete || (isAutocomplete && !filter.multiple)) {
      inputValue = existingValue || INPUT_DEFAULT_VALUE[inputType];
    } else inputValue = existingValue || [];

    setParam(inputValue);
  });
  if (Object.keys(params).length) {
    reset(params);
  }
};

// подготавливает объект фильтров для query
export const generateFilterQuery = (filters: FilterItem[], query: ParsedQs) => {
  const params: any = {};
  filters.forEach((filter) => {
    if (_.get(query, filter.name)) {
      const filterName = filter.name;
      const queryValue = _.get(query, filter.name);
      const setParam = (newData: string | ParsedQs | string[] | ParsedQs[] | undefined) =>
        _.set(params, filterName, newData);
      switch (filter.filterType) {
        case FilterType.CUSTOM_FILTER: {
          setParam(filter.customFilter?.(queryValue));
          break;
        }
        default:
          setParam(queryValue as string);
          break;
      }
    }
  });

  return Object.keys(params).length === 0 ? undefined : params;
};
