import React, { useEffect, useMemo, useState } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CompanyCard from "components/CompanyCard";
import AddButton from "components/buttons/AddButton";
import ROUTES from "constants/routes";
import { authRedirect } from "helpers/cookieHelper";
import CircularLoading from "components/CircularLoading";
import { useUserCompaniesQuery } from "@generated/graphql";
import cookies from "js-cookie";

const SelectCompany: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { redirectUrl } = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  const { data, loading, error } = useUserCompaniesQuery();
  const [initialLoading, setInitialLoading] = useState(true);

  const companies = data?.userCompanies;

  const handleNavigateAddNewCompany = () => navigate(`/${ROUTES.COMPANY_REGISTER}`);

  const onSelectCompany = (id: string) => {
    cookies.set("companyId", id);
    authRedirect({ redirectUrl });
  };

  useEffect(() => {
    if (!loading && !error) {
      if (companies?.length === 1) {
        const company = companies[0];
        onSelectCompany(company.id);
      } else {
        setInitialLoading(false);
      }
    }
  }, [loading, companies]);

  if (loading || initialLoading) return <CircularLoading />;

  return (
    <Card>
      <Typography
        variant='h28'
        sx={{ mb: 3.5, maxWidth: 556, width: "100vw" }}>
        Авторизация
      </Typography>
      <Stack spacing={3}>
        {companies?.map((company) => (
          <CompanyCard
            key={company.id}
            company={company}
            onCompanyClick={() => onSelectCompany(company.id)}
          />
        ))}
        <AddButton
          buttonText='добавить организацию'
          onClick={handleNavigateAddNewCompany}
        />
      </Stack>
    </Card>
  );
};

export default SelectCompany;
