import React, { useContext } from "react";
import { Card, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import CompanyForm from "components/CompanyForm";
import { useCreateOrUpdateCompanyMutation } from "@generated/graphql";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { CompanyRegisterForm } from "pages/Register/types";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/routes";
import { cleanSpaceInString } from "helpers/validation/createValidation";

const CompanyRegister: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const form = useForm<CompanyRegisterForm>();
  const { handleSubmit } = form;
  const [isDaDataInput, setIsDaDataInput] = React.useState(true);

  const [createCompany, { loading }] = useCreateOrUpdateCompanyMutation();

  const onSubmit = (formData: CompanyRegisterForm) => {
    createCompany({
      variables: {
        input: {
          managerPosition: formData.managerPosition,
          managerFio: formData.managerFio,
          shortName: cleanSpaceInString(formData.shortName),
          fullName: cleanSpaceInString(formData.fullName),
          inn: formData.inn,
          type: formData.type!.id,
          userPosition: cleanSpaceInString(formData.userPosition),
          ogrnDate: formData.ogrnDate.getTime(),
          ogrn: formData.ogrn,
          kpp: formData.kpp,
          okato: formData.okato || "",
          okpo: formData.okpo || "",
          oktmo: formData.oktmo || "",
          registrationDate: formData.registrationDate.getTime(),
          state: formData.state?.id!,
          registrationAddress: cleanSpaceInString(formData.registrationAddress),
        },
      },
    })
      .then((res) => {
        if (res.data?.createOrUpdateCompany.id) {
          addToast({ type: ToastTypeEnum.SUCCESS });
          cookies.set("companyId", res.data?.createOrUpdateCompany.id);
          navigate(`/${ROUTES.COMPETENCE_MAPS}`);
        } else addToast({ type: ToastTypeEnum.ERROR });
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => {
    addToast({ type: ToastTypeEnum.ERROR });
  };

  return (
    <Card sx={{ minWidth: "50vw", maxWidth: 700 }}>
      <Stack
        sx={{ mb: 3.5 }}
        direction={{ xs: "column", md: "row" }}
        justifyContent='space-between'>
        <Typography variant='h28'>Регистрация</Typography>
        <Button
          startIcon={<Add />}
          sx={({ typography }) => ({
            px: 0,
            justifyContent: "flex-start",
            color: "base.200",
            ...typography.body14rg,
          })}
          onClick={() => setIsDaDataInput(!isDaDataInput)}>
          {isDaDataInput ? "моей организации нет в списке" : "выбрать организацию из списка"}
        </Button>
      </Stack>
      <Grid
        container
        rowSpacing={3.5}
        columnSpacing={3.5}>
        <Grid
          item
          xs={12}>
          <CompanyForm
            form={form}
            isDaDataInput={isDaDataInput}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <LoadingButton
            loading={loading}
            variant='contained'
            size='large'
            onClick={handleSubmit(onSubmit, onError)}
            fullWidth>
            Зарегистрироваться
          </LoadingButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CompanyRegister;
