import React, { ReactNode } from "react";
import { FormControl, MenuItem, SelectChangeEvent, SelectProps, Stack, Typography } from "@mui/material";
import MuiSelect from "@mui/material/Select";
import { KeyboardArrowDown } from "@mui/icons-material";
import { FieldError } from "react-hook-form";

export interface ISelectItem {
  value: string;
  label: string;
}

export type SelectCustomProps<TSelectItem> = {
  items: TSelectItem[];
  fullWidth?: boolean;
  labelName?: keyof TSelectItem;
  valueName?: keyof TSelectItem;
  renderValue?: (value: TSelectItem) => React.ReactNode;
  onChange?: (event: SelectChangeEvent<TSelectItem>, child: React.ReactNode) => void;
  textColor?: "primary";
  onItemClick?: (item: TSelectItem) => void;
  error?: FieldError;
  value?: TSelectItem[keyof TSelectItem];
} & Omit<SelectProps, "onChange" | "error" | "value">;

const Select = <TSelectItem extends Record<string, any> = ISelectItem>({
  items,
  value,
  onChange,
  sx,
  labelName = "label",
  valueName = "value",
  readOnly,
  label,
  onItemClick,
  error,
  ...props
}: SelectCustomProps<TSelectItem>) => {
  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const typedEvent = event as SelectChangeEvent<TSelectItem>;
    onChange?.(typedEvent, child);
  };

  return (
    <FormControl
      fullWidth={props.fullWidth}
      component={Stack}
      spacing={0.5}
      error={!!error}>
      <Typography
        variant='body14rg'
        sx={{ color: "base.500" }}>
        {label}
      </Typography>
      <MuiSelect
        value={value}
        size='small'
        IconComponent={KeyboardArrowDown}
        onChange={handleChange}
        sx={sx}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: 0,
            horizontal: "center",
          },
        }}
        {...props}>
        {items.map((item) => (
          <MenuItem
            key={`select-item-${item[valueName || "value"]}`}
            onClick={() => onItemClick?.(item)}
            textColor={props.textColor}
            value={item[valueName || "value"]}>
            {item[labelName || "label"]}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
