import React, { useContext } from "react";
import ListLayout from "layout/ListLayout";
import {
  OrderType,
  PlatformTrialListOrderFields,
  PlatformTrialListOrderInput,
  TrialsQuery,
  useTrialsQuery,
} from "@generated/graphql";
import useSetTabTitle from "hooks/useSetTabTitle";
import { CompanyContext } from "context/companyContext";
import { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import TestingCard from "./TestingCard";
import filterFields from "./filterFields";

type TrialItem = NonNullable<TrialsQuery["trials"]["data"]>[number];

const TestingList = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const { data, loading, error, refetch } = useTrialsQuery({
    variables: {
      page: 0,
      limit: 8,
      filter: {},
      order: {
        field: PlatformTrialListOrderFields.CreatedAt,
        order: OrderType.Asc,
      },
    },
  });
  const list = data?.trials.data;

  const createAssessmentUnavailableText = generateUnavailableText(
    isCreateAvailable,
    currentCompany,
    "создания тестирования"
  );

  useSetTabTitle("Тестирования кандидатов");

  return (
    <ListLayout<TrialItem, PlatformTrialListOrderInput>
      createUnavailableText={createAssessmentUnavailableText}
      title='Тестирования кандидатов'
      filters={filterFields}
      CardComponent={TestingCard}
      list={list || []}
      loading={loading}
      error={error}
      refetch={refetch}
      totalCount={data?.trials.totalCount}
      emptyPlaceholderText='Пока нет тестирований'
      defaultOrder={{
        field: PlatformTrialListOrderFields.CreatedAt,
        order: OrderType.Desc,
      }}
      sortingList={[
        {
          buttonText: "По дате создания",
          fieldName: PlatformTrialListOrderFields.CreatedAt,
        },
        {
          buttonText: "По дате завершения",
          fieldName: PlatformTrialListOrderFields.CompletedAt,
        },
      ]}
    />
  );
};

export default TestingList;
