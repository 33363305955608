import { useEffect, useRef, useState } from "react";
import { Card, Grid, Stack, Typography, Button } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams, Link } from "react-router-dom";

import CircularLoading from "components/CircularLoading";
import EmptyList from "components/EmptyList";
import Form from "components/form/Form";

import { useCompetencyMapQuery } from "@generated/graphql";
import useIsCreate from "hooks/useIsCreate";
import useSetTabTitle from "hooks/useSetTabTitle";

import PlusIcon from "assets/icons/PlusIcon";
import ROUTES from "constants/routes";
import Info from "./components/Info";
import CopyMapModal from "./components/CopyMapModal";
import Header from "./components/Header";
import { CompetencyMapInputs } from "./components/types";
import withDragDropContext from "../../../hocs/withDragDropContext";
import CompetenciesList from "./components/CompetenciesList";

const CompetenciesListWithDragDropContext = withDragDropContext(CompetenciesList);

const CompetencyMapView = () => {
  const isCreate = useIsCreate();
  const [isEditable, setIsEditable] = useState<boolean>(isCreate);
  const blockContainerRef = useRef<HTMLDivElement>(null);
  const infoCardRef = useRef<HTMLDivElement>(null);

  const { mapId } = useParams();
  const { data, loading } = useCompetencyMapQuery({
    variables: { competencyMapId: mapId! },
    skip: !mapId,
  });

  const competencyMap = data?.competencyMap;
  const form = useForm<CompetencyMapInputs>({
    values: {
      id: competencyMap?.id,
      title: competencyMap?.title || "",
      comment: competencyMap?.comment,
      specialization: competencyMap?.specialization || "",
      grades: competencyMap?.grades?.join(", "),
      updatedAt: competencyMap?.updatedAt,
      createdAt: competencyMap?.createdAt,
      isRepositoryCheck: competencyMap?.isRepositoryCheck || false,
      knowledgeBlocks: competencyMap?.competences?.map((block) => ({
        id: block.id,
        title: block.title,
        knowledgeBlockCompetences: block.knowledgeBlockCompetences?.map((competence) => ({
          id: competence.knowledgeBlock.id,
          title: competence.knowledgeBlock.title,
          skills: competence.knowledgeBlock.skills,
          profession: competence.knowledgeBlock.profession,
        })),
      })),
    },
  });

  const { control } = form;
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: "knowledgeBlocks",
    keyName: "formId",
  });

  const title = `Карта компетенций ${competencyMap?.title ? ` «${competencyMap.title}»` : ""}`;
  useSetTabTitle(title, competencyMap?.title);

  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);

  const handleCopyMap = () => {
    setOpenCopyModal(true);
  };

  const [scrollHeight, setScrollHeight] = useState<number>(0);
  // const [infoCardHeight, setInfoCardHeight] = useState(0);

  useEffect(() => {
    if (blockContainerRef.current) {
      const currentScrollHeight = blockContainerRef.current.scrollHeight;
      if (currentScrollHeight > scrollHeight && scrollHeight !== 0) {
        blockContainerRef.current.scrollTo({
          top: blockContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
      setScrollHeight(blockContainerRef.current.scrollHeight);
    }
  }, [fields.length]);

  const originalCompetencyMap = competencyMap?.originalCompetencyMap;
  const infoCardHeight = infoCardRef.current?.clientHeight;

  if (loading) return <CircularLoading />;

  return (
    <>
      <Header
        competencyMap={competencyMap}
        append={append}
        isCreate={isCreate}
        isEditable={isEditable}
      />
      <Form form={form}>
        <Grid
          container
          columnSpacing={2.5}>
          <Grid
            item
            xs={12}
            md={9}>
            <Stack
              direction='column'
              sx={{
                height: "100%",
                maxHeight: `${infoCardHeight ? `${infoCardHeight}px` : "auto"}`,
                overflow: "auto",
              }}
              spacing={2.5}
              ref={blockContainerRef}>
              {!fields.length && !isEditable ? (
                <EmptyList emptyPlaceholderText='Пока нет компетенций' />
              ) : (
                <CompetenciesListWithDragDropContext
                  droppableId='competenciesList'
                  moveItems={move}
                  fields={fields}
                  form={form}
                  remove={remove}
                  isEditable={isEditable}
                />
              )}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <Stack spacing={2.5}>
              <Info
                form={form}
                isEditable={isEditable}
                infoCardRef={infoCardRef}
                setIsEditable={setIsEditable}
              />
              <Button
                startIcon={<PlusIcon sx={{ width: 16, height: 16 }} />}
                sx={({ typography }) => ({
                  px: 0,
                  justifyContent: "flex-end",
                  color: "base.200",
                  ...typography.body14rg,
                })}
                color='primary'
                onClick={handleCopyMap}>
                дублировать карту
              </Button>
              {originalCompetencyMap && (
                <Card>
                  <Typography variant='body16rg'>
                    Является дубликатом карты{" "}
                    <Link to={`/${ROUTES.COMPETENCE_MAPS}/view/${originalCompetencyMap.id}`}>
                      {originalCompetencyMap.title}
                    </Link>
                  </Typography>
                </Card>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
      {openCopyModal && competencyMap && (
        <CopyMapModal
          open={openCopyModal}
          onClose={() => setOpenCopyModal(false)}
          originalMap={{ id: competencyMap.id, title: competencyMap.title }}
        />
      )}
    </>
  );
};

export default CompetencyMapView;
