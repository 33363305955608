import { ButtonOwnProps, SxProps, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { CompanyContext } from "context/companyContext";
import { COMPANY_STATUS_TITLE } from "constants/company";
import { CompanyQuery } from "@generated/graphql";
import { LoadingButton } from "@mui/lab";

interface ButtonWithPermissionsProps {
  onClick?: () => void;
  tooltipText?: string;
  buttonText: string;
  size?: ButtonOwnProps["size"];
  sx?: SxProps;
  disabled?: boolean;
  loading?: boolean;
}

export const generateUnavailableText = (
  isCreateAvailable: boolean,
  currentCompany: CompanyQuery["company"] | undefined,
  entityTitle: string
) =>
  !isCreateAvailable && currentCompany?.status
    ? `Функционал ${entityTitle} недоступен, 
    так как компания находится в статусе 
    ${COMPANY_STATUS_TITLE[currentCompany?.status]}`
    : "";

const ButtonWithPermissions = ({
  onClick,
  tooltipText,
  buttonText,
  size = "medium",
  sx,
  disabled,
  loading,
}: ButtonWithPermissionsProps) => {
  const { isCreateAvailable } = useContext(CompanyContext);

  return (
    <Tooltip title={tooltipText}>
      <LoadingButton
        loading={loading}
        sx={{
          ...sx,
          "&.Mui-disabled": {
            pointerEvents: typeof isCreateAvailable === "undefined" || isCreateAvailable ? "none" : "auto",
          },
        }}
        variant='contained'
        color='primary'
        size={size}
        disabled={!isCreateAvailable || disabled}
        onClick={onClick}>
        {buttonText}
      </LoadingButton>
    </Tooltip>
  );
};

export default ButtonWithPermissions;
