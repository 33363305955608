import { InputType } from "components/generators/inputsGenerator";
import { FilterType } from "components/generators/filtersGenerator";
import { formatForFilter } from "helpers/date";
import { GRADE_OPTIONS } from "constants/global";
import { Grade } from "@generated/graphql";
import VALIDATION from "helpers/validation";

const filterFields = [
  {
    name: "createdAt",
    label: "Дата создания",
    inputType: InputType.DATE,
    filterType: FilterType.CUSTOM_FILTER,
    customFilter: (value: string) => formatForFilter(value, "start"),
  },
  {
    name: "title",
    label: "Название блока знаний",
    inputType: InputType.TEXT,
    rules: VALIDATION.competencyMapTitle,
  },

  {
    name: "grades",
    label: "Грейд",
    inputType: InputType.AUTOCOMPLETE,
    filterType: FilterType.CUSTOM_FILTER,
    options: GRADE_OPTIONS,
    customFilter: (grades: { id: string; displayName: Grade }[]) => grades.map((grade) => grade.id),
    limitTags: 1,
    multiple: true,
  },
];

export default filterFields;
