import { useParams } from "react-router-dom";
import { Trial_Status, TrialHeaderQuery, useSetTrialStatusMutation } from "@generated/graphql";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import useIsCreate from "hooks/useIsCreate";
import TestingStatus from "components/TestingStatus";
import useDownloadReport from "hooks/useDownloadReport";
import { ConfirmModalContext } from "context/confirmModalContext";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import useSetTabTitle from "hooks/useSetTabTitle";
import { LoadingButton } from "@mui/lab";
import withLanguagePopover from "hocs/withLanguagePopover";
import LanguageIcon from "assets/icons/LanguageIcon";

const LoadingButtonWithLanguage = withLanguagePopover(LoadingButton);

const Header = ({ trial }: TrialHeaderQuery) => {
  const { handleOpen } = useContext(ConfirmModalContext);
  const { addToast } = useContext(ToastContext);
  const isCreate = useIsCreate();
  const { trialId } = useParams();

  const [isWithExplanations, setIsWithExplanations] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWithExplanations(event.target.checked);
  };

  const { onDownloadReport, isLoading } = useDownloadReport(
    trialId!,
    isWithExplanations,
    trial?.companyCandidate.fullName,
    trial?.completedAt
  );

  const isCompleted = trial?.status === Trial_Status.Completed;
  const isCanCancel = trial?.status === Trial_Status.Created;

  const [setTrialStatus] = useSetTrialStatusMutation();

  const onCancel = () => {
    handleOpen({
      title: "Вы уверены, что хотите отменить тестирование?",
      description: "Кандидат не сможет приступить к тестированию после отмены.",
      actionButtonText: "Подтвердить",
      handleClick: async () =>
        setTrialStatus({
          variables: {
            input: {
              trialId: trialId!,
              newStatus: Trial_Status.Canceled,
            },
          },
        })
          .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
          .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });
  };
  const title = `Тестирование ${trial?.numericId ? ` ID ${trial?.numericId}` : ""}`;
  useSetTabTitle(title, trial?.numericId);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      mb={2.5}
      spacing={2}>
      <Typography variant='h28'>{title}</Typography>
      {!isCreate && (
        <Stack
          direction='row'
          spacing={2}
          alignItems='baseline'>
          {trial?.status && <TestingStatus status={trial?.status} />}
          <Button
            variant='contained'
            color='primary'
            disabled={!isCanCancel}
            onClick={onCancel}>
            Отменить тестирование
          </Button>
          <Stack spacing={1}>
            <LoadingButtonWithLanguage
              loading={isLoading}
              variant='contained'
              color='primary'
              disabled={!isCompleted}
              sx={{ gap: "6px" }}
              setLanguage={onDownloadReport}
              popoverProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                slotProps: { paper: { sx: { marginLeft: "-28px" } } },
              }}>
              Выгрузить отчет
              <LanguageIcon />
            </LoadingButtonWithLanguage>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isWithExplanations}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label='Расширенный отчет'
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
