import { Palette } from "@mui/material";

export default {
  primary: {
    main: "#1749D1",
    hover: "#053BB3",
  },
  background: {
    default: "#E4EBFF",
  },
  bgSwitch: "#F6F8FF",
  bgCase: "#F5F8FF",
  secondary: {
    main: "#FFC43B",
  },
  logo: "#FF5C00",
  blue: "#7499FF",
  divider: "#CCDAFF",
  text: {
    primary: "#212121",
    secondary: "#666666",
    disabled: "#9E9E9E",
  },
  base: {
    700: "#212121",
    500: "#666666",
    300: "#9E9E9E",
    200: "#A9A9A9",
    100: "#E0E0E0",
    50: "#F5F5F5",
  },
  error: {
    main: "#E53B35",
    contrastText: "#F6514C",
  },
  red: {
    contrast: "#E53B35",
    light: "#FDD5D4",
  },
  orange: {
    contrast: "#FF7405",
    light: "#FFE6AC",
  },
  green: {
    contrast: "#008836",
    light: "#D2F0DF",
  },
  status: {
    created: "#7499FF",
    received: "#1749D1",
    started: "#44BF78",
    completed: "#A9A9A9",
    canceled: "#212121",
  },
  border: "#E0E0E0",
} as Palette;
