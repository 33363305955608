import { Competency_Map_Status, Grade, Question_Type } from "@generated/graphql";

const GRADE_OPTIONS = [
  {
    id: Grade.Junior,
    displayName: "Junior",
  },
  {
    id: Grade.Middle,
    displayName: "Middle",
  },
  {
    id: Grade.Senior,
    displayName: "Senior",
  },
];

const QUESTION_TYPES = [
  {
    label: "Практический",
    value: Question_Type.Practical,
  },
  {
    label: "Теоретический",
    value: Question_Type.Theoretical,
  },
];

const COMPETENCY_MAP_STATUSES = [
  {
    label: "Драфт",
    value: Competency_Map_Status.Draft,
    color: "error" as const,
  },
  {
    label: "Подтверждено",
    value: Competency_Map_Status.Approved,
    color: "success" as const,
  },
];

const GENERATE_QUESTIONS_DEFAULT_AMOUNT = 3;

export { GRADE_OPTIONS, QUESTION_TYPES, COMPETENCY_MAP_STATUSES, GENERATE_QUESTIONS_DEFAULT_AMOUNT };
