import { useController, UseControllerProps } from "react-hook-form";
import React, { FC } from "react";
import { Checkbox, CheckboxProps, FormControlLabel, Typography } from "@mui/material";

export type TCheckboxProp = {
  name: string;
  label: string;
  handleChange?: (value: boolean) => void;
  rules?: UseControllerProps["rules"];
};

const CheckboxControl: FC<CheckboxProps & TCheckboxProp> = ({ name, label, disabled, handleChange, rules }) => {
  const {
    field: { value, onChange },
  } = useController({ name, defaultValue: false, rules });
  return (
    <FormControlLabel
      checked={value}
      onChange={(_, checked) => {
        onChange(checked);
        handleChange?.(checked);
      }}
      control={<Checkbox />}
      label={<Typography dangerouslySetInnerHTML={{ __html: `${label}` }} />}
      disabled={disabled}
    />
  );
};

export default CheckboxControl;
