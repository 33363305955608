import { useController, UseControllerProps } from "react-hook-form";
import { TextField, TextFieldProps, InputAdornment, Typography, Stack, IconButton } from "@mui/material";
import { ReactNode, useState } from "react";
import * as React from "react";
import { IMask, IMaskInput } from "react-imask";
import OpenEyeIcon from "assets/icons/OpenEyeIcon";
import CloseEyeIcon from "assets/icons/CloseEyeIcon";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";
import TriangleArrow from "assets/icons/TriangleArrow";

type OnNumberValueChangeProp = {
  onNumberValueChange: (value: number) => void;
};

type TextFieldControlProps<T extends React.HTMLInputTypeAttribute | undefined> = {
  name: string;
  endIcon?: ReactNode;
  handleChange?: (value: string, onChange: (newValue: string) => void) => void;
  type?: T;
  isError?: boolean;
  label?: string;
  maskType?: "time";
  maskProps?: { mask: string };
};

// eslint-disable-next-line react/display-name
const MaskCustom = React.forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value) => {
        onChange?.({ target: { name: props.name, value } });
      }}
      unmask
    />
  );
});

const TextFieldControl = <T extends React.HTMLInputTypeAttribute | undefined>({
  name,
  placeholder,
  variant,
  size,
  onNumberValueChange,
  rules,
  handleChange,
  label,
  type,
  maskProps,
  ...props
}: Omit<TextFieldProps, "label" | "type"> &
  TextFieldControlProps<T> &
  UseControllerProps<any> &
  (T extends "number" ? OnNumberValueChangeProp : Partial<OnNumberValueChangeProp>)) => {
  const {
    field,
    fieldState: { error },
  } = useController({ defaultValue: "", name, rules });

  const [showPassword, setShowPassword] = useState(false);

  const isPassword = type === "password";

  const isTimeInput = props.maskType === "time";

  const generateInputProps = () => {
    if (isTimeInput) {
      return {
        mask: "HH:MM",
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: "HH",
            from: 0,
            to: 23,
            maxLength: 2,
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: "MM",
            from: 0,
            to: 59,
            maxLength: 2,
          },
        },
      };
    }
    if (maskProps) return maskProps;
    return {};
  };

  const inputProps = generateInputProps();

  const getInputType = () => {
    if (isPassword) {
      if (!showPassword) return "password";
      return "text";
    }
    return type;
  };

  const inputType = getInputType();

  const handleChangeMinutes = (action: "up" | "down") => {
    if (inputType !== "number") return;

    const currentValueString = field.value as string;

    const currentValue = currentValueString ? parseInt(currentValueString, 10) : 0;

    let newValue = currentValue;
    if (action === "up") {
      newValue = currentValue + 1;
    } else {
      newValue = currentValue - 1;
    }

    (onNumberValueChange as OnNumberValueChangeProp["onNumberValueChange"])(newValue);
  };

  const generateEndIcon = () => {
    if (isPassword) {
      return (
        <IconButton
          onClick={() => setShowPassword(!showPassword)}
          edge='end'>
          {showPassword ? <OpenEyeIcon sx={{ color: "base.200" }} /> : <CloseEyeIcon sx={{ color: "base.200" }} />}
        </IconButton>
      );
    }
    if (inputType === "number") {
      return (
        <Stack>
          <TriangleArrow
            onClick={() => handleChangeMinutes("up")}
            sx={{
              color: "base.200",
              width: 14,
              height: 14,
              cursor: "pointer",
            }}
          />
          <TriangleArrow
            onClick={() => handleChangeMinutes("down")}
            sx={{
              color: "base.200",
              transform: "rotate(180deg)",
              width: 14,
              height: 14,
              cursor: "pointer",
            }}
          />
        </Stack>
      );
    }
    if (props.endIcon) return props.endIcon;
    return undefined;
  };
  const endIcon = generateEndIcon();

  return (
    <Stack
      spacing={0.5}
      sx={{ width: props.fullWidth ? "100%" : undefined }}>
      {label && (
        <Typography
          variant='body14rg'
          sx={{ color: "base.500" }}>
          {label}
        </Typography>
      )}
      <TextField
        {...props}
        {...field}
        onChange={(e) => {
          const { value } = e.target;
          if (handleChange) handleChange(value, field.onChange);
          else field.onChange(value);
        }}
        size={size}
        error={!!error || props.isError}
        fullWidth
        placeholder={placeholder || label}
        type={inputType === "number" ? "text" : inputType}
        variant={variant}
        helperText={error?.message}
        InputProps={{
          endAdornment: endIcon ? <InputAdornment position='end'>{endIcon}</InputAdornment> : undefined,
          inputProps,
          inputComponent: maskProps?.mask || isTimeInput ? MaskCustom : undefined,
        }}
      />
    </Stack>
  );
};

export default TextFieldControl;
